import React from 'react';
import Seo from '../components/Seo';

export default function ContactPage() {
  return (
    <article>
      <Seo title="Contact Harriet Gibsone" />
      <h2>Contact</h2>

      <p>
        <a href="mailto:ruth@featherstonecairns.com" title="Email Ruth at Featherstone Cairns - Harriet's literary agent">
          ruth@featherstonecairns.com
        </a>{' '}
        - <i>(literary agent)</i>
      </p>
    </article>
  );
}
